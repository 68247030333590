import { cn } from "@lib/utils";

export const InstructionSection = ({ title, description, index }: any) => (
  <div className="mb-8 ">
    {index === 0 ? (
      <h4 className="font-medium"> </h4>
    ) : (
      <div className="flex items-center justify-left gap-2 mb-2">
        <div className="w-5 h-5 rounded-full bg-main text-white text-xs flex items-center justify-center">
          ✓
        </div>
        <h4 className="font-medium">{title}</h4>
      </div>
    )}

    <div>
      <div className="text-gray-800">
        <p
          className={cn(index === 0 ? "" : "bg-gray-300 p-4")}
          // style={{ textAlign: "justify" }}
        >
          {description}
        </p>
      </div>
    </div>
  </div>
);
