export interface Instruction {
  title?: string;
  description: string;
}
export const instructionsData: { [key: string]: Instruction[] } = {
  revenue: [
    {
      description:
        "Revenue should encompass all income-generating activities directly related to the business's core operations, as well as any additional income sources.",
    },
    {
      title: "Separating revenue streams",
      description:
        "Revenue segments with similar profitability, growth, and risk profiles are good candidates for separation. Some businesses benefit from segmenting by sales channel (e.g., B2C vs. B2B), while others may find it useful to segment by product or service type. For instance, a tech company might separate hardware sales from software subscriptions, or a consulting firm could distinguish between project-based services and retainer-based advisory services.",
    },
  ],

  opex: [
    {
      description:
        "Include all operating expenses, excluding cost of goods sold, which is covered separately. This typically encompasses FTE cost, rent, and selling, general and administrative (SG&A) expenses, among others.",
    },
    {
      title: "Opex vs capex",
      description:
        "Operational expenses include daily operational costs like rent and salaries, while capital expenditures involve long-term investments in assets such as property, machinery, and vehicles. Operational expenses are fully expensed immediately, directly reducing profit, whereas capital expenditures are capitalized and depreciated over time.",
    },
  ],

  fixedAssets: [
    {
      description:
        "Specify long-term assets, such as tangible assets like property, plant & equipment (PP&E), or intangible assets (e.g., goodwill), which are intended to provide economic benefits over several fiscal years.",
    },
    {
      title: "Existing vs new fixed assets",
      description:
        "For existing fixed assets, use the 'depreciation of opening balance' field to match the scheduled depreciation obligation. This input can, of course, be ignored for new fixed assets. Set the depreciation rate per the asset category for existing assets, or apply a standard rate estimation for new assets.",
    },
  ],

  nwc: [
    {
      description:
        "Working capital comprises current assets and liabilities and can either contribute to cash flow or strain it. Optimizing (trade) working capital involves shortening the cash conversion cycle (CCC) while ensuring sufficient cash to meet short-term obligations.",
    },
    {
      title: "Number of days",
      description:
        "The 'number of days' input reflects the speed at which cash is paid or received. It's often best to base this estimate on historical data and/or existing (contractual) agreements with customers and suppliers. An increase in trade receivables and inventory days lengthens the CCC, whereas trade payables reduce it.",
    },
    {
      title: "Other current assets and liabilities",
      description:
        "Include all assets and liabilities not covered in other sections (e.g., fixed assets, debt) relevant to the business. This may include items such as prepaid expenses, accrued income, provisions, and tax credits/payables, among others.",
    },
  ],

  debt: [
    {
      description:
        "Specify debt items, such as bank loans, lines of credit, or trade credit, which involve repaying borrowed funds with interest. Items without an interest component should fall under 'other current liabilities' (see working capital section).",
    },
    {
      title: "Existing vs new debt",
      description:
        "For existing debt, use the 'repayment of opening balance' field to match the scheduled repayment obligation. This input can, of course, be ignored for new debt. Set the interest rate as the annualized rate per the loan agreement for existing debt, or apply a market-based assessment for new debt.",
    },
    {
      title: "Short vs long-term debt",
      description:
        "While financial statements distinguish between short-term debt (due within a year) and long-term debt on the balance sheet, this distinction is not necessary for financial forecasting and valuation as it adds unneeded complexity to the inputs. For this reason, aggregate short- and long-term debt.",
    },
    {
      title: "Leases",
      description:
        "Include financial leases as part of debt when relevant, as they involve repayment obligations and interest expenses similar to traditional debt. In contrast, operational leases pertain to rented assets without ownership transfer and, under certain accounting practices, may not appear on the balance sheet. Note that IFRS 16, which mandates including most operational leases on the balance sheet, is currently not supported.",
    },
  ],

  equity: [
    {
      description:
        "Common equity represents ownership interests in the company and entitles holders to voting rights and residual profits after debt is paid.",
    },
    {
      title: "Dividends",
      description:
        "Ensure the cash balance remains positive across all forecast periods when specifying dividends, and that the equity closing balance doesn’t fall below zero.",
    },
    {
      title: "Preferred and convertible equity",
      description:
        "Note that instruments such as preferred equity or convertible notes are not currently supported.",
    },
  ],
};
