import React from "react";
import { InstructionSection } from "./InstructionSection";
import { Instruction } from "./instructionsData";

const InstructionSections = ({
  instructions,
}: {
  instructions: Instruction[];
}) => {
  return (
    <div className="font-light text-sm flex flex-col ">
      {instructions.map((instruction: any, index: any) => (
        <InstructionSection
          key={index}
          title={instruction.title}
          description={instruction.description}
          index={index}
        />
      ))}
    </div>
  );
};

export default InstructionSections;
