export const AGGREGATION_ITEM_KEY = {
  pl: "total",
  bs: "closingBalance",
  cf: "total",
};

export const statementTypes = Object.keys(
  AGGREGATION_ITEM_KEY
) as (keyof typeof AGGREGATION_ITEM_KEY)[];

export type StatementType = (typeof statementTypes)[number];

export interface FinstatItem {
  label: string;
  isTotal?: boolean;
  isDynamic?: boolean;
  isNwcItem?: boolean;
  isNested: boolean;
}

export type FinstatFormat = {
  [key in StatementType]: Record<string, FinstatItem>;
};
