import { dynamicFinstatItems } from "./sheets/sheets";
import { FinstatFormat, statementTypes } from "@interfaces/finstat";

import { FinstatItem } from "@interfaces/finstat";

export const finstatFormat: FinstatFormat = {
  pl: {
    revenue: {
      label: "Revenue",
      isDynamic: true,
      isNested: true,
    },
    cogs: {
      label: "COGS",
      isDynamic: true,
      isNested: true,
    },
    gm: {
      label: "Gross margin",
      isDynamic: true,
      isNested: true,
    },
    opex: {
      label: "Operating Expenses",
      isDynamic: true,
      isNested: true,
    },
    ebitda: {
      label: "EBITDA",
      isTotal: true,
      isNested: false,
    },
    depreciation: {
      label: "Depreciation",
      isNested: false,
    },
    amortization: {
      label: "Amortization",
      isNested: false,
    },
    ebit: {
      label: "EBIT",
      isTotal: true,
      isNested: false,
    },
    interestExpenses: {
      label: "Interest Expenses",
      isNested: false,
    },
    ebt: {
      label: "EBT",
      isTotal: true,
      isNested: false,
    },
    taxExpenses: {
      label: "Tax Expenses",
      isNested: false,
    },
    netIncome: {
      label: "Net Income",
      isTotal: true,
      isNested: false,
    },
  },
  bs: {
    fixedAssets: {
      label: "Fixed Assets",
      isDynamic: true,
      isNested: true,
    },
    dta: {
      label: "Deferred tax assets",
      isDynamic: false,
      isNested: false,
    },
    tradeReceivables: {
      label: "Trade Receivables",
      isNwcItem: true,
      isNested: false,
    },
    inventory: {
      label: "Inventory",
      isNwcItem: true,
      isNested: false,
    },
    otherCurrentAssets: {
      label: "Other Current Assets",
      isNwcItem: true,
      isNested: false,
    },
    currentAssets: {
      label: "Current Assets",
      isTotal: true,
      isNested: false,
    },
    totalAssets: {
      label: "Total Assets",
      isTotal: true,
      isNested: false,
    },
    cash: {
      label: "Cash",
      isNested: false,
    },
    commonEquity: {
      label: "Common Equity",
      isNested: false,
    },
    equity: {
      label: "Equity",
      isTotal: true,
      isNested: true,
    },
    debt: {
      label: "Debt",
      isDynamic: true,
      isNested: true,
    },
    tradePayables: {
      label: "Trade Payables",
      isNwcItem: true,
      isNested: false,
    },
    otherCurrentLiabilities: {
      label: "Other Current Liabilities",
      isNwcItem: true,
      isNested: false,
    },
    currentLiabilities: {
      label: "Current Liabilities",
      isTotal: true,
      isNested: false,
    },
    totalEquityAndLiabilities: {
      label: "Total Equity and Liabilities",
      isTotal: true,
      isNested: false,
    },
  },
  cf: {
    ebitda: {
      label: "EBITDA",
      isNested: false,
    },
    changeNwc: {
      label: "Change in net working capital",
      isNested: false,
    },
    taxesPaid: {
      label: "Taxes Paid",
      isNested: false,
    },
    interestPaid: {
      label: "Interest Paid",
      isNested: false,
    },
    cfOperations: {
      label: "Cash Flow from Operations",
      isTotal: true,
      isNested: false,
    },
    capex: {
      label: "Capital Expenditures",
      isNested: false,
    },
    cfInvesting: {
      label: "Cash Flow from Investing",
      isTotal: true,
      isNested: false,
    },
    debtDrawdownRepayment: {
      label: "Debt Drawdown and Repayment",
      isNested: false,
    },
    equityFundingDividend: {
      label: "Equity Funding and Dividend",
      isNested: false,
    },
    cfFinancing: {
      label: "Cash Flow from Financing",
      isTotal: true,
      isNested: false,
    },
    cf: {
      label: "Total Cash Flow",
      isTotal: true,
      isNested: false,
    },
  },
};

export const getFilteredFinstatSections = ({
  statementTypes,
  conditions,
}: {
  statementTypes: (keyof FinstatFormat)[];
  conditions: {
    key: keyof FinstatItem;
    value: boolean;
  }[];
}) => {
  return statementTypes.flatMap((section) =>
    Object.keys(finstatFormat[section]).filter((key) => {
      return conditions.some(
        (condition) =>
          (finstatFormat[section][key]?.[condition.key] ?? false) ===
          condition.value
      );
    })
  );
};

export const nwcItems = getFilteredFinstatSections({
  statementTypes: ["bs"],
  conditions: [{ key: "isNwcItem", value: true }],
});

export const actualInputLineItems = {
  pl: Object.keys(finstatFormat.pl).filter(
    (key) => !finstatFormat.pl[key].isTotal
  ),
  bs: Object.keys(finstatFormat.bs).filter(
    (key) => !finstatFormat.bs[key].isTotal
  ),
  cf: Object.keys(finstatFormat.cf).filter(
    (key) => !finstatFormat.cf[key].isTotal
  ),
};

export const storedStaticItems = [
  ...actualInputLineItems.pl,
  ...actualInputLineItems.bs,
].filter((item) => !dynamicFinstatItems.includes(item));

export const finstatLineItems = statementTypes.reduce((acc, type) => {
  acc[type] = {};
  Object.keys(finstatFormat[type]).forEach((item) => {
    acc[type][item] = {};
  });
  return acc;
}, {} as Record<keyof FinstatFormat, Record<string, {}>>);

export const getNonDynamicFinstatSections = (
  statementTypes: (keyof FinstatFormat)[]
) =>
  statementTypes.flatMap((section) =>
    Object.keys(finstatFormat[section]).filter(
      (key) =>
        !finstatFormat[section][key]?.isTotal &&
        finstatFormat[section][key]?.isDynamic !== true
    )
  );

export const getNonTotalFinstatSections = (
  statementTypes: (keyof FinstatFormat)[]
) =>
  statementTypes.flatMap((section) =>
    Object.keys(finstatFormat[section]).filter(
      (key) => !finstatFormat[section][key]?.isTotal
    )
  );

export const getTotalFinstatSections = (
  statementTypes: (keyof FinstatFormat)[]
) =>
  statementTypes.flatMap((section) =>
    Object.keys(finstatFormat[section]).filter(
      (key) => finstatFormat[section][key]?.isTotal
    )
  );

export const getNestedFinstatSections = (
  statementTypes: (keyof FinstatFormat)[]
) =>
  statementTypes.flatMap((section) =>
    Object.keys(finstatFormat[section]).filter(
      (key) => finstatFormat[section][key]?.isNested
    )
  );

export const getNoneNestedFinstatSections = (
  statementTypes: (keyof FinstatFormat)[]
) =>
  statementTypes.flatMap((section) =>
    Object.keys(finstatFormat[section]).filter(
      (key) => !finstatFormat[section][key]?.isNested
    )
  );
