// utils ts
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import { getServerSession } from "next-auth/next";
import { NextRequest, NextResponse } from "next/server";
import { FinstatSectionType } from "@interfaces/general";
import { actualInputLineItems, finstatFormat } from "@constants/finstat";
import { StatementType } from "@interfaces/finstat";

export function convertToFormOptionsFormat(array: any[]) {
  return array.map((item) => ({ label: item, value: item }));
}

export function generatePeriodCounter(numberOfPeriods: number) {
  return Array.from({ length: numberOfPeriods }, (_, i) => i + 1);
}

export function objectToMap(obj: any) {
  return new Map(Object.entries(obj));
}

export function convertToObj(data: any): Record<string, any> {
  if (data instanceof Map) {
    return Object.fromEntries(data);
  }
  return data;
}

export function isObjectEmpty(obj: any) {
  return Object.keys(obj).length === 0;
}

export function getStatementType(section: string): FinstatSectionType | null {
  for (const [statementType, sections] of Object.entries(
    actualInputLineItems
  )) {
    if (sections.includes(section)) {
      return statementType as FinstatSectionType;
    }

    if (section === "nwc" || section === "equity") {
      return "bs" as FinstatSectionType;
    }
  }
  return null; // Return null if the section doesn't match any statement type
}

export function getStatementType2(sectionId: string) {
  for (const statementType in finstatFormat) {
    if (!statementType) return null;
    if (
      finstatFormat[statementType as StatementType].hasOwnProperty(sectionId)
    ) {
      return statementType;
    }
  }
  return null; // or throw an error if the sectionId is not found
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function generateInitials(fullName: string) {
  const names = fullName.split(" ");
  const initials =
    names.length > 1
      ? `${names[0][0]}${names[names.length - 1][0]}`
      : fullName.substring(0, 2);
  return initials.toUpperCase();
}

export function formatDate(dateString: string) {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
}

export function formatDateFromUnixTimestamp(unixTimestamp: number) {
  if (!unixTimestamp) return "";
  const date = new Date(unixTimestamp * 1000); // Convert seconds to milliseconds
  return formatDate(date.toISOString());
}

export function getIdFromUrl(url: URL, item: string) {
  const pathSegments = url.pathname.split("/");

  const index = pathSegments.findIndex((segment) => segment === item) + 1;
  const id = pathSegments[index];

  return id || null;
}

export async function authenticateUser(authOptions: any) {
  const session = (await getServerSession(authOptions)) as any;
  const userId = session?.user?.id || null;
  return userId;
}

// export function convertToFormOptionsFormat(
//   strings: string[]
// ): { value: string; label: string }[] {
//   return strings.map((str) => ({ value: str, label: str }));
// }

export const getParam = (param: string, req: NextRequest) =>
  req.nextUrl.searchParams.get(param);

export function getForecastIdentifiers(req: NextRequest) {
  const section = getParam("section", req); // e.g. revenue
  const subSection = getParam("subSection", req); // e.g. services
  const forecastCase = getParam("forecastCase", req); // e.g. base

  return { section, subSection, forecastCase };
}

export function getFinstatIdentifiers(req: NextRequest) {
  const finstatPeriod = getParam("finstatPeriod", req);
  const finstatSection = getParam("finstatSection", req);

  return { finstatPeriod, finstatSection };
}

export function offsetValues({
  numberArr,
  numberOfPeriods,
}: {
  numberArr: number[];
  numberOfPeriods: number;
}): number[] {
  if (numberOfPeriods === 0) return numberArr;

  const offsetArray = new Array(numberOfPeriods).fill(0).concat(numberArr);
  return offsetArray.slice(0, numberArr.length);
}

export function renamePropObj({
  obj,
  oldPropKey,
  newPropKey,
}: {
  obj: { [key: string]: any };
  oldPropKey: string;
  newPropKey: string;
}) {
  if (oldPropKey === newPropKey) {
    return obj;
  }

  if (obj.hasOwnProperty(oldPropKey)) {
    obj[newPropKey] = obj[oldPropKey];
    delete obj[oldPropKey];
  }

  return obj;
}

export function roundNumberArr(numberArr: number[], decimals = 0) {
  return numberArr.map((num: number) => roundNumber(num, decimals));
}

export function roundNumber(num: number | null, decimals = 0) {
  if (num === null) return null;
  const factor = Math.pow(10, decimals);
  return Math.round(num * factor) / factor;
}

export function sanitiseAndRoundNumber(value: number): string {
  if (isNaN(value)) {
    return "n/a";
  }

  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(value);
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
