import { sheetOptions } from "@constants/sheets/sheetOptions";

export const sheetSections = Object.keys(sheetOptions);

// const staticSheetSections = ["nwc", "macroeconomic", "equity"];

// export const dynamicSheetSections = sheetSections.filter(
//   (section) => !staticSheetSections.includes(section)
// );

export const dynamicSheetSections = Object.keys(sheetOptions).filter(
  (key) => sheetOptions[key].isDynamicSheet
);

export type DynamicSheetSectionType = (typeof dynamicSheetSections)[number];

export const dynamicFinstatItems = [...dynamicSheetSections, "gm"];

export const cases = ["low", "base", "high"];

export const forecastCases = cases.map((caseItem) => ({
  value: caseItem,
  label: `${caseItem.charAt(0).toUpperCase() + caseItem.slice(1)} case`,
}));

export const dynamicSubSectionSuffixes =
  generateDynamicSubSectionSuffixes(dynamicFinstatItems);

export function generateDynamicSubSectionSuffixes(items: string[]): {
  [key: string]: string;
} {
  const result: { [key: string]: string } = {};
  for (const item of items) {
    result[item] = item;
  }
  return result;
}
