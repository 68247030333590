import InstructionSections from "./Instructions/InstructionSections";
import { instructionsData } from "./Instructions/instructionsData";

export const sheetOptions: any = {
  macroeconomic: {
    id: "macroeconomic",
    title: "Macroeconomic",
    isDynamicSheet: false,
    timeLineType: "forecast",
    sideBarLinkOrder: 1,
  },

  revenue: {
    id: "revenue",
    title: "Revenue",
    isDynamicSheet: true,
    timeLineType: "T0forecast",
    sideBarLinkOrder: 2,
    maxItems: 5,
    instructions: (
      <InstructionSections instructions={instructionsData.revenue} />
    ),
  },

  opex: {
    id: "opex",
    title: "Operational cost",
    isDynamicSheet: true,
    timeLineType: "T0forecast",
    sideBarLinkOrder: 4,
    maxItems: 10,
    instructions: <InstructionSections instructions={instructionsData.opex} />,
  },

  cogs: {
    id: "cogs",
    title: "Cost of goods sold",
    isDynamicSheet: true,
    isEditableSheet: false,
    timeLineType: "T0forecast",
    sideBarLinkOrder: 3,
    maxItems: 5,
  },

  fixedAssets: {
    id: "fixedAssets",
    title: "Fixed assets",
    isDynamicSheet: true,
    timeLineType: "T0forecast",
    sideBarLinkOrder: 5,
    maxItems: 5,
    instructions: (
      <InstructionSections instructions={instructionsData.fixedAssets} />
    ),
  },

  nwc: {
    id: "nwc",
    title: "Working capital",
    isDynamicSheet: false,
    timeLineType: "T0forecast",
    sideBarLinkOrder: 6,
    instructions: <InstructionSections instructions={instructionsData.nwc} />,
  },

  debt: {
    id: "debt",
    title: "Debt",
    isDynamicSheet: true,
    timeLineType: "T0forecast",
    sideBarLinkOrder: 7,
    maxItems: 5,
    instructions: <InstructionSections instructions={instructionsData.debt} />,
  },

  equity: {
    id: "equity",
    title: "Equity",
    isDynamicSheet: false,
    isEditableSheet: false,
    timeLineType: "T0forecast",
    sideBarLinkOrder: 8,
    instructions: (
      <InstructionSections instructions={instructionsData.equity} />
    ),
  },
};
